import { Country, CountryCode, Currency, CurrencyCode } from '@/shared/types';

export const ACCESS_TOKEN_EXPIRY_CHECK_INTERVAL = 2 * 60 * 1000; // 2 minute
export const TOKEN_CHECK_INTERVAL = 30 * 1000; // 30 seconds
export const USER_IDLE_TIMEOUT = 5 * 60 * 1000; // 5 minutes

/** 30 minutes */
export const defaultStaleTime = 30 * 60 * 1000;

// localStorage keys
export const LOCAL_STORAGE_KEYS = {
  CURRENT_USER: 'currentUser',
  DEVICE_INFO: 'deviceInfo',
};

// cookie storage keys
export const STORAGE_KEYS = {
  CLIENT_ACCESS_TOKEN_STORAGE_KEY: 'fx.remittance.access.token',
  CLIENT_REFRESH_TOKEN_STORAGE_KEY: 'fx.remittance.refresh.token',
};

/** A collection of countries */
export const countryCollection: Record<CountryCode, Country> = {
  NG: {
    code: 'NG',
    name: 'Nigeria',
    demonym: 'Nigerian',
    callingCode: '234',
    currencyCode: 'NGN',
    bankCodeLength: {
      min: 3,
      max: 3,
    },
    backAccountNumberLength: {
      min: 10,
      max: 10,
    },
  },
  GB: {
    code: 'GB',
    name: 'United Kingdom',
    demonym: 'British',
    callingCode: '44',
    currencyCode: 'GBP',
    bankCodeLength: {
      min: 6,
      max: 6,
    },
    backAccountNumberLength: {
      min: 8,
      max: 8,
    },
  },
};

/** A collection of currencies */
export const currencyCollection: Record<CurrencyCode, Currency> = {
  NGN: {
    code: 'NGN',
    name: 'Nigerian Naira',
    symbol: '₦',
    countryCode: 'NG',
  },
  GBP: {
    code: 'GBP',
    name: 'British Pound',
    symbol: '£',
    countryCode: 'GB',
  },
};

export const DEFAULT_RESEND_IN_SECONDS = 30;