import {
  MutationStatus,
  UseQueryResult,
  isServer,
} from '@tanstack/react-query';
import { type ClassValue, clsx } from 'clsx';
import { intervalToDuration } from 'date-fns';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import isNil from 'lodash/isNil';
import { twMerge } from 'tailwind-merge';

import {
  CountryCode,
  CurrencyCode,
  DeviceInfoCookie,
  RawDate,
} from '@/shared/types';

import {
  LOCAL_STORAGE_KEYS,
  STORAGE_KEYS,
  countryCollection,
} from './constants';
import { logger } from './telemetry';

import { config } from '@/config';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const isEmpty = (value: any): boolean => {
  if (value == null) {
    return true;
  }

  if (typeof value === 'string' && value.trim().length === 0) {
    return true;
  }

  if (Array.isArray(value) && value.length === 0) {
    return true;
  }

  if (typeof value === 'object' && Object.keys(value).length === 0) {
    return true;
  }

  return false;
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function log(...args: any[]) {
  if (process.env.NODE_ENV !== 'development') logger.log(...args);
  // eslint-disable-next-line no-console
  else console.log('[DEV]', ...args);
}

export function debugLog(...args: any[]) {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('[DEV]', ...args);
  }
}

export function logError(...args: any[]) {
  if (process.env.NODE_ENV !== 'development') logger.error(...args);
  // eslint-disable-next-line no-console
  else console.error('[DEV]', ...args);
}

export function isClient() {
  return typeof window !== 'undefined';
}

export function is401Error(error: any) {
  return error?.response?.status === 401;
}

export function is404Error(error: any) {
  return error?.response?.status === 404;
}

export function is429Error(error: any) {
  return error?.response?.status === 429;
}

export function is422Error(error: any) {
  return error?.response?.status === 422;
}

// =============================================================================
// Case converters
// =============================================================================
export function capitalize(
  string: string,
  {
    lowerRest = false,
    ignoreRest = false,
  }: { lowerRest?: boolean; ignoreRest?: boolean } = {},
) {
  return string
    ?.split(' ')
    .map((word, index) => {
      const firstLetter = (() => {
        if (ignoreRest && index > 0) {
          return word.charAt(0);
        }

        if (lowerRest && index > 0) {
          return word.charAt(0).toLowerCase();
        }

        return word.charAt(0).toUpperCase();
      })();

      const rest =
        ignoreRest && index > 0 ? word.slice(1) : word.slice(1).toLowerCase();

      return `${firstLetter}${rest}`;
    })
    .join(' ');
}

/** Convert a string to screaming snake case */
export function toScreamingSnakeCase(string: string) {
  // If already in SCREAMING_SNAKE_CASE, return as-is
  if (/^[A-Z][A-Z0-9]*(?:_[A-Z0-9]+)*$/.test(string)) {
    return string;
  }

  return string
    .split(/(?=[A-Z])|[\s_\.]+/) // Added \. to also split on dots
    .map((word) => word.trim())
    .filter(Boolean)
    .join('_')
    .toUpperCase();
}

// =============================================================================
// Randomizers
// =============================================================================

export function generateRandomString(length: number) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export function generateRandomNumber(max: number) {
  return Math.floor(Math.random() * max);
}

// =============================================================================
// DOM / BOM
// =============================================================================

export function getLocalStorageItem(
  name: string,
): Record<string, unknown> | string | null {
  if (typeof localStorage === 'undefined' || isNil(name)) {
    return null;
  }

  const obfuscatedValue = localStorage.getItem(name);
  if (!obfuscatedValue) {
    return null;
  }

  const stringifiedValue = atob(obfuscatedValue);

  try {
    return JSON.parse(stringifiedValue);
  } catch (e) {
    return stringifiedValue;
  }
}

export function setLocalStorageItem(name: string, value: unknown): void {
  if (typeof localStorage === 'undefined' || isNil(value) || isNil(name)) {
    return;
  }

  const stringifiedValue =
    typeof value === 'object' ? JSON.stringify(value) : value?.toString();

  if (isNil(stringifiedValue)) {
    return;
  }

  const obfuscatedValue = btoa(stringifiedValue);

  localStorage.setItem(name, obfuscatedValue);
}

export function removeLocalStorageItem(name: string): void {
  if (typeof localStorage === 'undefined' || isNil(name)) {
    return;
  }
  localStorage.removeItem(name);
}

export function setCookie(
  name: string,
  value: string,
  maxAgeInSeconds: string | number,
): void {
  if (typeof document === 'undefined') {
    return;
  }
  document.cookie = `${name}=${value};  path=/; max-age=${maxAgeInSeconds}`;
}

export function getCookie(name: string): string | undefined {
  if (typeof document === 'undefined') {
    return;
  }
  const cookies = document.cookie.split(';');

  for (const cookie of cookies) {
    const [key, value] = cookie.split('=');
    if (key.trim() === name) {
      return value;
    }
  }

  return undefined;
}

export function removeCookie(name: string) {
  if (typeof document === 'undefined') {
    return;
  }
  document.cookie = `${name}=; path=/; max-age=0`;
}

export function parseJwt(token: string) {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
}

export const storeTokens = (tokens: Array<{ key: string; token: string }>) => {
  for (const { key, token } of tokens) {
    try {
      const { exp } = parseJwt(token) || {};
      setCookie(key, token, exp ?? config.SESSION_EXPIRY);
    } catch (e) {
      logError('Failed to store tokens');
    }
  }
};

export const restoreToken = (key: string): string | undefined => {
  return isServer ? undefined : getCookie(key);
};

export const removeToken = (key: string) => {
  removeCookie(key);
};

export function storeAccessToken(accessToken: string) {
  try {
    const { exp } = parseJwt(accessToken) || {};
    setCookie(
      STORAGE_KEYS.CLIENT_ACCESS_TOKEN_STORAGE_KEY,
      accessToken,
      exp ?? config.SESSION_EXPIRY,
    );
  } catch (e) {
    logError('Failed to store access token', e);
  }
}

export function restoreAccessToken(): string | undefined {
  return isServer
    ? undefined
    : getCookie(STORAGE_KEYS.CLIENT_ACCESS_TOKEN_STORAGE_KEY);
}

export function removeAccessToken() {
  removeCookie(STORAGE_KEYS.CLIENT_ACCESS_TOKEN_STORAGE_KEY);
}

// =============================================================================
// Internationalization
// =============================================================================
export function findCountryCodeByCallingCode(
  callingCode: string,
): CountryCode | undefined {
  for (const [countryCode, countryInfo] of Object.entries(countryCollection)) {
    if (countryInfo.callingCode === callingCode) {
      return countryCode as CountryCode;
    }
  }

  // Return undefined if no matching calling code is found
  return undefined;
}

export function findCountryCodeByCurrencyCode(
  currencyCode: CurrencyCode,
): CountryCode | undefined {
  for (const [countryCode, countryInfo] of Object.entries(countryCollection)) {
    if (countryInfo.currencyCode === currencyCode) {
      return countryCode as CountryCode;
    }
  }

  // Return undefined if no matching currency code is found
  return undefined;
}

/**
 * Find the currency code of a country by its country code.
 * @param { CountryCode } countryCode
 * @returns { CurrencyCode | undefined }
 */
export function findCurrencyCodeByCountryCode(
  countryCode: CountryCode,
): CurrencyCode | undefined {
  const countryInfo = countryCollection[countryCode];

  if (!countryInfo) {
    return undefined;
  }

  return countryInfo.currencyCode;
}

/**
 * @param { number } value
 * @param { CurrencyCode } currency
 * @param { Intl.NumberFormatOptions } options
 */
export function formatCurrency(
  value: number,
  currency: CurrencyCode,
  options?: Intl.NumberFormatOptions,
  reversed: boolean = false,
) {
  const formattedCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...options,
  })
    .format(value)
    .split(/\s/);

  if (reversed) {
    return formattedCurrency.reverse().join(' ');
  }

  return formattedCurrency.join(' ');
}

// Format date as Day Month (without year)
export function formatDateAsDayMonthName(date: RawDate): string {
  if (!date || new Date(date).toString() === 'Invalid Date') {
    throw new Error("Invalid Date");
  }
  const dateObject = dayjs.utc(date).local();

  // Format the date according to the user's local time zone
  const formattedDate = dateObject.format('D MMMM');
  return formattedDate;
}

export function convertToRelativeTime(
  today: Date,
  date: RawDate,
  options: { includeWeekday?: boolean; includeYear?: boolean } = {
    includeWeekday: true,
    includeYear: true,
  },
): string | void {
  if (!date || new Date(date).toString() === 'Invalid Date') {
    return;
  }

  const { includeWeekday = true, includeYear = true } = options;
  const dateObj = dayjs.utc(date).local();

  const todayString = dayjs(today).format('YYYY-MM-DD');
  const yesterdayString = dayjs(today).subtract(1, 'day').format('YYYY-MM-DD');
  const dateString = dateObj.format('YYYY-MM-DD');

  const time = dateObj.format('h:mm A');

  if (todayString === dateString) {
    return `Today at ${time}`;
  } else if (yesterdayString === dateString) {
    return `Yesterday at ${time}`;
  } else {
    let formattedDate = dateObj.format(
      `${includeWeekday ? 'dddd, ' : ''}MMMM D, ${includeYear ? 'YYYY' : ''} % h:mm A`,
    );
    formattedDate = formattedDate.replace('%', ' at ');
    return formattedDate;
  }
}

// Convert UTC timestamp to local date
export const convertUtcTimestampToLocalDate = (utcTimestamp: string) => {
  const utcDate = dayjs.utc(utcTimestamp);

  const localDate = utcDate.local().toDate(); // Convert UTC to local time
  return localDate;
};

export function formatDateAsDayMonthNameYear(date: RawDate): string {
  if (!date || new Date(date).toString() === 'Invalid Date') {
    throw new Error("Invalid Date");
  }
  
  const dateObject = dayjs.utc(date).local();

  const formattedDate = dateObject.format('D MMMM YYYY');
  return formattedDate;
}

export function getHumanizedDate(date: RawDate): string {
  if (!date || new Date(date).toString() === 'Invalid Date') {
    throw new Error("Invalid Date");
  }

  const dateObject = dayjs.utc(date).local();

  return dateObject.year() === dayjs().year()
    ? formatDateAsDayMonthName(date)
    : formatDateAsDayMonthNameYear(date);
}

// Get time left as formatted string (HH:MM)
export const getTimeLeft = (completionTime: string): string => {
  const now = dayjs().local();
  const completionDate = dayjs.utc(completionTime).local();

  if (completionDate.isBefore(now)) {
    return '00:00';
  }

  const duration = intervalToDuration({
    start: now.toDate(),
    end: completionDate.toDate(),
  });

  const parts = [];
  if (duration.days) parts.push(`${duration.days}`);
  if (duration.hours !== undefined)
    parts.push(duration.hours.toString().padStart(2, '0'));
  if (duration.minutes !== undefined)
    parts.push(duration.minutes.toString().padStart(2, '0'));

  return parts.join(':');
};

// Format date as YYYY-MM-DD in local time zone
export function formatDateAsYYYYMMDD(date: RawDate): string {
  if (!date || new Date(date).toString() === 'Invalid Date') {
    throw new Error("Invalid Date");
  }

  const dateObject = dayjs.utc(date).local();
  const formattedDate = dateObject.format('YYYY-MM-DD');
  return formattedDate;
}

export const formatTime12Hour = (dateString: string): string => {
  try {
    const date = dayjs.utc(dateString).local();
    return date.format('hh:mm a').toLowerCase();
  } catch (error) {
    return '--:--'; // Fallback in case of an error
  }
};

export function formatNumber(
  value: number,
  options?: Intl.NumberFormatOptions,
) {
  return new Intl.NumberFormat('en-US', options).format(value);
}

export const getRedirectUrl = (key: string) => {
  const redirectPath = getCookie(key);
  if (redirectPath) {
    return decodeURIComponent(redirectPath);
  }
};

export const setRedirectUrl = (
  key: string,
  {
    url,
    expiry = 1000 * 60 * 60 * 24,
  }: { url?: string | null; expiry?: number } = {},
) => {
  if (url) {
    setCookie(key, encodeURIComponent(url), expiry);
  } else {
    removeCookie(key);
  }
};

export const getExistingDeviceInfo = () => {
  const deviceInfo = getLocalStorageItem(LOCAL_STORAGE_KEYS.DEVICE_INFO);
  if (deviceInfo) {
    return deviceInfo as unknown as DeviceInfoCookie;
  }
};

export const setDeviceInfoInLocalstorage = (deviceInfo: DeviceInfoCookie) => {
  setLocalStorageItem(LOCAL_STORAGE_KEYS.DEVICE_INFO, deviceInfo);
};

export const noop = () => {};

export function stringifyObject(object: Record<any, any>) {
  return Object.entries(object).map(([key, value]) => [key, String(value)]);
}

export function mergeQueries(
  queries: UseQueryResult[],
  options: {
    /**
     * If true, the merged query will be empty if all the queries are empty
     * @default false
     */
    emptyWhenAllEmpty?: boolean;
  } = {},
) {
  const { emptyWhenAllEmpty = false } = options;

  const error = queries.find((query) => query.isError)?.error;
  const isError = queries.some((query) => query.isError);
  const isLoading = queries.some((query) => query.isLoading);
  const isSuccess = queries.every((query) => query.isSuccess);
  const isFetching = queries.some((query) => query.isFetching);
  const isRefetching = queries.some((query) => query.isRefetching);

  const empty = emptyWhenAllEmpty
    ? queries.every((query) => isEmpty(query.data))
    : queries.some((query) => isEmpty(query.data));

  function refetch() {
    queries.forEach((query) => query.refetch());
  }

  return {
    error,
    isEmpty: empty,
    isError,
    isLoading,
    isSuccess,
    isFetching,
    isRefetching,
    refetch,
  };
}

/**
 * Merges multiple mutation statuses into a single status.
 * @param statuses - The statuses to merge.
 * @returns The merged status.
 */
export function mergeMutationStatus(
  ...statuses: (MutationStatus | undefined)[]
): MutationStatus {
  // Pending if any status is pending
  if (statuses.some((status) => status === 'pending')) {
    return 'pending';
  }

  // Error if any status is error
  if (statuses.some((status) => status === 'error')) {
    return 'error';
  }

  // Success if all statuses are success
  if (statuses.every((status) => status === 'success')) {
    return 'success';
  }

  // Return idle if none of the conditions are met
  return 'idle';
}
