import { createTelemetry } from '@web-platform/telemetry';

import { config } from '@/config';

const ENABLE = config.ENABLE_MONITORING;
const BASE_URL = config.TELEMETRY_BASE_URL;
const SERVICE_NAME = config.TELEMETRY_SERVICE_NAME;
const DEBUG_MODE = config.TELEMETRY_DEBUG_MODE;
const DEV_MODE = config.TELEMETRY_DEV_MODE;
const ENVIRONMENT = config.APP_ENVIRONMENT;
const INTERVAL = config.TELEMETRY_METRIC_INTERVAL;

const options = {
  collectorBaseUrl: BASE_URL,
  serviceName: SERVICE_NAME,
  showInBrowserConsole: DEBUG_MODE,
  devMode: DEV_MODE,
  environment: ENVIRONMENT,
  disable: !ENABLE,
  exportIntervalsMillis: Number(INTERVAL),
}

const telemetry = createTelemetry(options);

const metrics = telemetry.metrics;
const tracing = telemetry.tracing;

const logger = telemetry.logging.setup(SERVICE_NAME);

export { logger, metrics, tracing };
